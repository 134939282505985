<template>
  <div class="contact" id="body">
    <div class="section-title">
							<h1>Kontakt</h1>
							<div class="header-border grey" />
    <form id="contact-form" method="post" action="api/contact.php" role="form">

      <div class="container">

      <div class="controls">

            <div class="row">
                <div class="col-md-6">
                    <div class="form-field">
                        <input id="name" type="text" v-model="name" name="name" minlength="2" maxlength="256" placeholder=" " required="required" data-error="Der Name wird benötigt.">
                        <label for="name" class="label-name">
                            <span class="content-name">Name *</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field">
                        <input id="surname" type="text" v-model="surname" name="surname" minlength="2" maxlength="256" placeholder=" " required="required" data-error="Der Nachname wird benötigt.">
                        <label for="surname" class="label-name">
                            <span class="content-name">Nachname *</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-field">
                        <input id="email" type="email" v-model="email" name="email" placeholder=" " required="required" data-error="Eine richtige E-Mail wird benötigt.">
                        <label for="email" class="label-name">
                            <span class="content-name">E-Mail *</span>
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-field">
                        <!--<input id="subject" type="text" name="subject" minlength="3" maxlength="256" placeholder=" " required="required" :value="(typeof $route.params.topic !== 'undefined') ? $route.params.topic + ': ' + $route.params.subtopic : '' " data-error="Der Betreff wird benötigt.">-->
                        <input id="subject" type="text" name="subject" minlength="3" maxlength="256" placeholder=" " required="required" v-model="subject" data-error="Der Betreff wird benötigt.">
                        <label for="subject" class="label-name">
                            <span class="content-name">Betreff *</span>
                        </label>
                    </div>
                </div>
            </div>
            <!--<div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                      <label for="form_name">Name *</label>
                      <input id="form_name" type="text" name="name" class="form-control" placeholder="z.B. Max" required="required" data-error="Firstname is required.">
                      <div class="help-block with-errors"></div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label for="form_lastname">Nachname *</label>
                      <input id="form_lastname" type="text" name="surname" class="form-control" placeholder="z.B. Mustermann" required="required" data-error="Lastname is required.">
                      <div class="help-block with-errors"></div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-md-6">
                  <div class="form-group">
                      <label for="form_email">Email *</label>
                      <input id="form_email" type="email" name="email" class="form-control" placeholder="E-Mail Adresse benötigt" required="required" data-error="Valid email is required.">
                      <div class="help-block with-errors"></div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group">
                      <label for="form_need">Thema *</label>
                      <select id="form_need" name="need" class="form-control" required="required" data-error="Bitte spezifizieren Sie Ihr Anliegen.">
                          <option value=""></option>
                          <option value="Request quotation">Thema 1</option>
                          <option value="Request order status">Thema 2</option>
                          <option value="Request copy of an invoice">Thema 3</option>
                          <option value="Other">Etwas anderes...</option>
                      </select>
                      <div class="help-block with-errors"></div>
                  </div>
              </div>
          </div>-->
          <div class="row">
              <div class="col-md-12">
                  <div class="form-group">
                      <label for="form_message">Nachricht *</label>
                      <textarea id="form_message" v-model="message" name="message" class="form-control" placeholder="Ihre Nachricht ..." rows="4" required="required" data-error="Please, leave us a message."></textarea>
                      <div class="help-block with-errors"></div>
                  </div>
              </div>
              <div class="col-md-12">
                  <vue-recaptcha @verify="onVerify" sitekey="6Lf7AeAUAAAAAPDK7ovKaPqqD1NDCCzmhhwbMDSL"><input v-if="!success" type="submit" class="btn btn-outline-dark" value="Nachricht senden"></vue-recaptcha>
                  <input v-if="success" type="submit" class="btn btn-outline-dark" value="Nachricht senden" disabled>
                  
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <p class="text-muted">
                    <strong>*</strong> Diese Felder müssen zum Absenden Ihrer Nachricht ausgefüllt sein.</p>
            </div>

            <div class="col-md-12">
                <p class="text-muted">
                    Diese Website ist durch reCAPTCHA geschützt und es gelten die
                    <a href="https://policies.google.com/privacy">Datenschutzbestimmungen</a> und
                    <a href="https://policies.google.com/terms">Nutzungsbedingungen</a> von Google.</p>
            </div>
        </div>
        <div v-if="success" class="alert alert-success" role="alert">
            Die Nachricht wurde erfolgreich versendet!
        </div>
        <div v-if="failed" class="alert alert-danger" role="alert">
            Beim Versenden der Nachricht ist ein Fehler aufgetreten!
        </div>
      </div>
</div>
</form>
			</div>
  </div>
</template>

<script>
// @ is an alias to /src
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: "contact",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      name: null,
      surname: null,
      email: null,
      subject: null,
      message: null,
      success: null,
      failed: null
    }
  },
  methods: {
    onVerify: function (response) {
        var formData = new FormData();
        formData.append("name", this.name);
        formData.append("surname", this.surname);
        formData.append("email", this.email);
        formData.append("message", this.message);
        formData.append("subject", this.subject);
        this.$http.post('api/contact.php', formData)
        .then(function(res) {
            this.success = true;
        })
        .catch(function(error) {
            this.failed = true;
        })
    },
	topic() {
        if (typeof $route.params.topic !== "undefined")
        {
            document.getElementById("subject").value = $route.params.topic + ": " + $route.params.subtopic;
        }
	}
  }
};
</script>

