<template>
  <div class="career" id="body">
    <div class="section-title">
							<h1>Karriere</h1>
							<div class="header-border grey" />


    <div class="container">
                <p class="career-des"> Für unsere renommierten Kunden besetzen wir ab sofort im Rahmen einer Direktvermittlung folgende Positionen. Alle zu besetzenden Stellen sind unabhängig von Geschlecht, Nationalität, ethnischer und sozialer Herkunft, Religion / Weltanschauung, Behinderung, Alter sowie sexueller Orientierung und Identität:</p>
      <div v-if="loading" class="box">
        <a data-toggle="collapse" href="#career-des-">
          <div class="header">
            <p>Loading...</p>
          </div>
        </a>
        <div class="description" id="#career-des-">
          <p>Loading...</p>
        </div>
      </div>
      <div v-for="job in jobs" :key="job.id" class="box">
        <a v-on:click="job.isactive = !job.isactive">
          <div class="header">
            <p>{{ job.title }}</p>
          </div>
        </a>
        <div class="description" v-bind:class="{ active: job.isactive}" v-html="job.description">
          {{ job.description }}
        </div>
      </div>
        <!--<div class="box">
          <a data-toggle="collapse" href="#career-des-1">
            <div class="header">
              <p>Moderator für Live Hacking Team (Information Security Awareness) (m, w, d)</p>
            </div>
          </a>
          <div class="description collapse" id="career-des-1">
            <p>Für den Einsatz an unserem Standort in Köln sowie bundesweit suchen wir einen für die Moderation in unseren Live Hacking Teams geeigneten Moderator zur Festanstellung. <br>Sie besitzen fundierte Kenntnisse und praktische Erfahrungen in: <br><br>

 <ul>
  <li>Grundverständnis</li>
  <ul>
    <li>IT Umfeld</li>
    <li>Hacking Szene</li>
  </ul> 
  <li>Praktische Erfahrung</li>
  <ul>
    <li>Live Hacking</li>
    <li>Moderation</li>
  </ul> 
  <li>Option</li>
  <ul>
    <li>Erfahrung in Awareness-Kampagnen</li>
  </ul> 
</ul> 
Ihre aussagekräftigen Bewerbungsunterlagen senden Sie bitte unter Angabe Ihrer Gehaltsvorstellung an:
<br><br>
<strong>Kontakt:</strong><br>
Businessprotect24<br>
Geschäftsführung<br>
Herr Frank Amaliadis<br>
Hermannstrasse 17<br>
41061 Mönchengladbach<br>
<br>
oder via Mail an: frank.amaliadis@businessprotect24.de
</p>
          </div>
        </div>-->
        <!--<a href="">
        <div class="box" href="#">
          <div class="header">
            <p>Techniker für Live Hacking Team (Information Security Awareness) (m, w, d)</p>
          </div>
          <div class="description collapse">
            <p>Description</p>
          </div>
        </div>
        </a>-->
    </div>
			</div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "career",
  components: {
    
  },
  methods: {
  },
  data() {
    return {
      jobs: [],
      loading: false
    }
  },
  mounted() {
    this.loading = true;
    this.$http.get('https://businessconsult24.de/directus/public/businessconsult24/items/career')
    .then(function(res) {
      this.jobs = res.body.data;
      this.loading = false;
    })
    .catch(function(error) {
      this.loading = true;
    })
  }
};
</script>
