<template>
  <div class="news" id="body">
    <div class="section-title">
							<h1>News</h1>
							<div class="header-border grey" />
    
			</div>
  <div class="container">
      <div class="row">
		<div class="col-md-12">
			<ul class="timeline">
				<li v-for="post in news" :key="post.id">
          <h2>{{ post.title }}</h2>
          <span class="float-right"><font-awesome-icon class="icon" icon="calendar-alt" /> {{ post.created_on }}</span>
          <div class="box" v-html="post.text">
            {{ post.text }}
          </div>
					<!--<h2>Das gesamte Team der Businessconsult24 wünscht Ihnen ein gesundes und erfolgreiches neues Jahr!</h2>
					<span class="float-right"><font-awesome-icon class="icon" icon="calendar-alt" /> Donnerstag, 1. Januar 2020</span>
                    <div class="box">
                        <img src="../assets/images/happy-new-year.jpg" alt="Frohe Weihnachten!">
                    </div> -->
				</li>
			</ul>
		</div>
	</div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "news",
  components: {
    
  },
  methods: {
  },
  data() {
    return {
      news: []
    }
  },
  mounted() {
    this.$http.get('https://businessconsult24.de/directus/public/businessconsult24/items/news')
    .then(function(res) {
      this.news = res.body.data;
    })
    .catch(function(error) {

    })
  }
};
</script>

